.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .social-media-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-left: 100px;
    margin-top: 25px;
  }
  
  .social-media-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  
