.home-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    color: #ffffff;
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #ffffff;
    color: #333;
    font-size: 1.2rem;
    padding: 12px 24px;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #333;
    color: #ffffff;
  }
  
