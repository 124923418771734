.navbar-nav {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-item {
    margin-left: 1rem;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem;
    font-size: 1.2rem;
  }
  
  .nav-link:hover {
    background-color: #e9ecef;
    border-radius: 10px;
  }

  
  
  .side-menu {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    padding: 2rem;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1000;
  }
  
  .side-menu.open {
    transform: translateX(0%);
  }
  
  .side-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .side-menu ul li {
    margin-bottom: 1rem;
  }

  .menu-btn {
    width: 30px;
    height: 22px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .menu-btn .bar {
    width: 100%;
    height: 3px;
    background-color: #343a40;
    transition: all 0.3s ease;
  }
  
  .menu-btn.open .bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }
  
  .menu-btn.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-btn.open .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
  