.skill-bubble-d3 {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .skill-bubble-d3-hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
