.contact-container {
    padding: 2rem 0;
    text-align: center;
  }
  
  .contact-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .form-control {
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .submit-button {
    display: inline-block;
    background-color: #333;
    color: #fff;
    font-size: 1.2rem;
    padding: 12px 24px;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #555;
    color: #fff;
  }
  