/* Global Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  a {
    color: #333;
    text-decoration: none;
  }
  
  a:hover {
    color: #555;
  }
  
  .container {
    max-width: 1100px;
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
  }
  
  .bg-primary {
    background-color: #f4f4f4;
  }
  
  .my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  
  .py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  