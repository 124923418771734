#about {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

#about img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
}

#about p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 30px;
}

.education-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.education-container h3 {
  font-size: 2.2rem;
  margin-bottom: 30px;
}


.experience-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.experience-container h3 {
  font-size: 2.2rem;
  margin-bottom: 30px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}


.vertical-timeline.vertical-timeline-custom-line::before {
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--education .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
